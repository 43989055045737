<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">服务组织管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="loginname" placeholder="请输入登录名" class="iw"></el-input>
                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-select v-model="stype" placeholder="选择类型" filterable clearable class="iwu">
                    <el-option v-for="item in serveTypeList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="DisplayName" label="名称"></el-table-column>
                    <el-table-column prop="CooperativeType" label="类型"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="Address" label="地址"></el-table-column>
                    <el-table-column prop="RegionName" label="区域"></el-table-column>
                    <el-table-column prop="SYSUSERDISPLAYNAME" label="上级用户">
                      <template slot-scope="scope">
                        <div>{{getUpperLevelUserName(scope.row.ParentSnowID)}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="CONTACTNAME" label="联系人"></el-table-column>
                    <el-table-column prop="CONTACTPHONE" label="联系人手机号"></el-table-column>
                    <el-table-column prop="LEGALPERSONNAME" label="法人"></el-table-column>
                    <el-table-column prop="LEGALPERSONPHONE" label="法人手机号"></el-table-column>
                    <el-table-column prop="IDNUMBER" label="身份证号"></el-table-column>
                    <el-table-column prop="UNIQUEID" label="统一社会信用代码"></el-table-column>
                    <el-table-column prop="ISNEEDUPLOAD" label="是否需要上传到黑龙江">
                      <template slot-scope="scope" >
                        <span v-if="scope.row.ISNEEDUPLOAD==1" style="color:#67C23A">是</span>
                        <span v-else style="color:#F56C6C">否</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="INTRODUCE" label="简介"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                            <el-button v-if="sysType == '1'" @click="searchReport(scope.row)" type="text" size="small"
                                style="color:#409EFF">查看作业报告</el-button>
                            <el-button @click="exportMachineTrack(scope.row)" type="text" size="small"
                                style="color:#409EFF;margin-left:0;" v-if="sysType == '1'">导出农机轨迹</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" top="30px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginName">
                    <el-input v-model="postInfo.loginName" autocomplete="off" placeholder="登录名只能为电话号码"></el-input>
                </el-form-item>
                <el-form-item v-if="doTypeStr === '添加服务组织'" label="密码" prop="PassWord">
                    <el-input v-model="postInfo.PassWord" autocomplete="off" :disabled="doType == '2'"
                          placeholder="登录密码默认为123456"></el-input>
                </el-form-item >
                <el-form-item label="统一社会信用代码" prop="UNIQUEID">
                    <el-input v-model="postInfo.UNIQUEID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上级用户" prop="ParentSnowID" v-if="sysType == '1'">
                    <el-select v-model="postInfo.ParentSnowID" style="width:100%;" @change="sysUserChange" :disabled="doTypeStr == '修改服务组织'">
                        <el-option v-for="item in sysUser" :key="item.SNOWID" :label="item.DISPLAYNAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域" prop="regionsnowids">
                    <el-cascader ref="cascader" v-model="postInfo.regionsnowids" :options="tree" :props="treeProps" placeholder="点击选择区域"
                        filterable style="width:100%" @change="regCascaderChange"></el-cascader>
                </el-form-item>
                <el-form-item label="服务组织类型" prop="cooperativetypesnowid">
                    <el-select v-model="postInfo.cooperativetypesnowid" style="width:100%;">
                        <el-option v-for="item in serveTypeList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input v-model="postInfo.address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactname">
                    <el-input v-model="postInfo.contactname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号" prop="contactphone">
                    <el-input v-model="postInfo.contactphone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="法人" prop="legalpersonname">
                    <el-input v-model="postInfo.legalpersonname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="法人手机号" prop="legalpersonphone">
                    <el-input v-model="postInfo.legalpersonphone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="IDNUMBER">
                    <el-input v-model="postInfo.IDNUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否需要上传到黑龙江">
                    <el-radio v-model="postInfo.ISNEEDUPLOAD" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISNEEDUPLOAD" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否禁用">
                    <el-radio v-model="postInfo.isblack" label="1">是</el-radio>
                    <el-radio v-model="postInfo.isblack" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model="postInfo.introduce" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="资质证照" style="overflow:hidden;">
                    <div style="width:50%;float:left;">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false" :before-upload="beforeYYZZ">
                            <el-image v-if="yyzz.src != ''" :src="yyzz.src" class="imgItem"></el-image>
                            <el-button v-else size="small" type="primary">点击上传</el-button>
                        </el-upload>
                        <p>点击选择营业执照图片</p>
                    </div>
                    <div style="width:50%;float:left;">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false" :before-upload="beforeKHXK">
                            <el-image v-if="khxk.src != ''" :src="khxk.src" class="imgItem"></el-image>
                            <el-button v-else size="small" type="primary">点击上传</el-button>
                        </el-upload>
                        <p>点击选择开户许可证图片</p>
                    </div>
                </el-form-item>
                <el-form-item label="身份证" style="overflow:hidden;">
                    <div style="width:50%;float:left;">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false" :before-upload="beforeSFZZ">
                            <el-image v-if="sfzz.src != ''" :src="sfzz.src" class="imgItem"></el-image>
                            <el-button v-else size="small" type="primary">点击上传</el-button>
                        </el-upload>
                        <p>点击选择身份证正面照</p>
                    </div>
                    <div style="width:50%;float:left;">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false" :before-upload="beforeSFZF">
                            <el-image v-if="sfzf.src != ''" :src="sfzf.src" class="imgItem"></el-image>
                            <el-button v-else size="small" type="primary">点击上传</el-button>
                        </el-upload>
                        <p>点击选择身份证反面照</p>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="searchModalTitle" :visible.sync="searchModal" width="1600px" top="30px"
            :close-on-click-modal="false">
            <SearchReport mechineid="" :sysuserid="sysuserid" :tableHeight="dialogMaxHeigh" ref="search"></SearchReport>
        </el-dialog>
        <el-dialog :title="exportMachineTrackStr" :visible.sync="exportMachineTrackModal" width="1200px"  :close-on-click-modal="false" top="50px">
            <div style="margin-bottom:10px;">
                <p style="float:left;margin-top:9px;">选择数据时间：</p>
                <el-date-picker v-model="exportMachineTrackTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:70%;"></el-date-picker>
                <el-button type="primary" @click="submitExportMachineTrackForm()" style="margin-left:10px;">生成文件</el-button>
                <el-button type="success" @click="refFileTableList()">刷新列表</el-button>
            </div>
            <el-table :data="FileList" stripe style="width: 100%" :height="(dialogMaxHeigh-100)"> 
                    <el-table-column prop="CooperativeUserName" label="服务组织"></el-table-column>
                    <el-table-column prop="DateStart" label="数据开始时间"></el-table-column>
                    <el-table-column prop="DateEnd" label="数据结束时间"></el-table-column>
                    <el-table-column prop="CreatorName" label="创建人"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建时间"></el-table-column>
                    <el-table-column prop="STUTAS" label="状态" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STUTAS==0" style="color:#409EFF">生成中</span>
                            <span v-else-if="scope.row.STUTAS==1" style="color:#67C23A">生成完成</span>
                            <span v-else-if="scope.row.STUTAS==2" style="color:#F56C6C">生成异常</span>
                            <span v-else-if="scope.row.STUTAS==3" style="color:#E6A23C">用户取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="INFO" label="备注">
                        <template slot-scope="scope">
                            <span v-if="scope.row.INFO">{{scope.row.INFO}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="downFile(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.STUTAS==1">下载</el-button>
                            <el-button @click="cancelFile(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="scope.row.STUTAS==0">取消</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="FPageIndex" :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
import SearchReport from "../../components/SearchReport"
export default {
    name: "name",
    components: {
        SearchReport,
    },
    data() {
        var displayname = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var cooperativetypesnowid = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择服务组织类型"));
            } else {
                callback();
            }
        };
        var address = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地址"));
            } else {
                callback();
            }
        };
        var loginName = (rule, value, callback) => {
            if (value === "") {
              callback(new Error("请输入登录名"));
            } else if (!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)) {
              callback(new Error('手机号输入有误'));
            } else {
              callback();
            }
        };
        var legalpersonname = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入法人"));
            } else {
                callback();
            }
        };
        var legalpersonphone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入法人手机号名"));
            } else if (!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)) {
                callback(new Error('手机号输入有误'));
            } else {
                callback();
            }
        };
        var contactname = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入联系人"));
            } else {
                callback();
            }
        };
        var contactphone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入联系人手机号"));
            } else if (!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)) {
                callback(new Error('手机号输入有误'));
            } else {
                callback();
            }
        };
        var introduce = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入简介"));
            } else {
                callback();
            }
        };
        var ParentSnowID = (rule, value, callback) => {
            if (this.sysType == '1' && value === "") {
                callback(new Error("请选择上级用户"));
            } else {
                callback();
            }
        };
        var regionsnowids = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error("请选择区域"));
            } else {
                callback();
            }
        };
        var IDNUMBER = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入身份证号"));
            } else if (!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
                callback(new Error('身份证号输入有误'));
            } else {
                callback();
            }
        };
        var UNIQUEID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入统一社会信用代码"));
            } else {
                callback();
            }
        };


        return {
            sysType: "1",

            sysUser: [],
            allList: [],

            regionList: [],
            regId: "",
            regUserId: "",
            canDo: true,

            serveTypeList: [],

            name: "",
            loginname: "",
            stype: "",

            tree: [],
            treeProps: {
                value: "SNOWID",
                label: "NAME",
                children: "Child",
                checkStrictly: true,
            },

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                displayname: [{ validator: displayname, trigger: "blur" }],
                cooperativetypesnowid: [{ validator: cooperativetypesnowid, trigger: "blur" }],
                regionsnowids: [{ validator: regionsnowids, trigger: "blur" }],
                address: [{ validator: address, trigger: "blur" }],
                loginName: [{ validator: loginName, trigger: "blur" }],
                legalpersonname: [{ validator: legalpersonname, trigger: "blur" }],
                legalpersonphone: [{ validator: legalpersonphone, trigger: "blur" }],
                contactname: [{ validator: contactname, trigger: "blur" }],
                contactphone: [{ validator: contactphone, trigger: "blur" }],
                introduce: [{ validator: introduce, trigger: "blur" }],
                ParentSnowID: [{ validator: ParentSnowID, trigger: "blur" }],
                IDNUMBER: [{ validator: IDNUMBER, trigger: "blur" }],
                UNIQUEID: [{ validator: UNIQUEID, trigger: "blur" }],
            },

            yyzz: { file: null, src: "", snowid: "" },
            khxk: { file: null, src: "", snowid: "" },
            sfzz: { file: null, src: "", snowid: "" },
            sfzf: { file: null, src: "", snowid: "" },

            sysuserid: "",
            searchModalTitle: "",
            searchModal: false,
            dialogMaxHeigh: 0,

            exportMachineTrackStr: "",
            exportMachineTrackModal: false,
            exportMachineTrackTime:[],


            fileSysItem:null,
            FileList:[],
            FPageIndex: 1,
            FPageSize: 10,
            FTotal: 0,

        };
    },
    mounted() {
        this.sysType = this.$store.state.sysType
        if (this.sysType == "2") {
            this.regId = this.$store.state.xzqSnowid
            this.regUserId = this.$store.state.xzqUserSnowid
        }
        if (this.sysType == "1") {
            this.getSysUserList()
        }
        this.dialogMaxHeigh = h - 200
        this.loadItem = null
        this.getRegionList()
        this.getServeTypeList()
        this.getAllList()
        this.getPostList()
    },
    methods: {
        //通过用户获取所在区域及子区域
        sysUserChange(e) {
            this.$http.post("Region/GetDataTree", { SysUserSnowID: e })
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.tree = response.data.DATA
                    }
                })
        },
        getRegTreeByRegSnowid() {
            this.$http.post("Region/GetDataTree", { parentSnowID: this.regId })
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.tree = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        regCascaderChange(e) {
            if(e.length === 1){
              this.postInfo.regionsnowids = ''
              this.$nextTick(() => {
                // 清空级联选择器选中状态
                this.$refs.cascader.$refs.panel.clearCheckedNodes()
                // 清除高亮
                this.$refs.cascader.$refs.panel.activePath = []
              })
              return this.$message.error('选中区域错误,只能选中镇或者街道')
            }
            if (e.length == 0) {
                this.postInfo.regionsnowid = ""
            } else {
                this.postInfo.regionsnowid = e[e.length - 1]
            }
        },
        getSysUserList() {
            this.$http.post("SysUser/GetSysUserList", { TYPE: 2 })
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.sysUser = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getAllList() {
            this.$http.post("Region/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.allList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getRegionList() {
            this.$http.post("Region/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.regionList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getServeTypeList() {
            this.$http.post("CooperativeUserType/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.serveTypeList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                name: this.name,
                //LOGINNAME: this.loginname,
                cooperativetypeSnowID: this.stype,
                // regionSnowID:this.regId,
            }
            this.$http.post("CooperativeUser/GetDataList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        addPost() {
            this.yyzz = { file: null, src: "", snowid: "" }
            this.khxk = { file: null, src: "", snowid: "" }
            this.sfzz = { file: null, src: "", snowid: "" }
            this.sfzf = { file: null, src: "", snowid: "" }
            this.postInfo = {
                displayname: "",
                regionsnowid: this.regId,
                regionsnowids: [],
                cooperativetypesnowid: "",
                address: "",
                legalpersonname: "",
                legalpersonphone: "",
                contactname: "",
                contactphone: "",
                introduce: "",
                isblack: "0",
                loginName: "",
                PassWord: '',
                ParentSnowID: "",
                businesslicensepath: "0",
                accountopeningpermitpath: "0",
                idcardfrontpath: "0",
                idcardbackpath: "0",
                IDNUMBER:"",
                UNIQUEID:"",
                ISNEEDUPLOAD:"0",
            }
            if (this.sysType == "2") {
                this.postInfo.ParentSnowID = ''
                this.getRegTreeByRegSnowid()
            }
            this.doTypeStr = "添加服务组织"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            if (this.sysType == "2") {
              this.postInfo.ParentSnowID = ''
            }
            let params=this.postInfo
            params.ISNEEDUPLOAD = parseInt(this.postInfo.ISNEEDUPLOAD)
            params.isblack = parseInt(this.postInfo.isblack)
            this.$http.post("CooperativeUser/InsertData", params)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        var cid = response.data.DATA
                        this.upLoadImg(this.yyzz.file, cid, 1)
                        this.upLoadImg(this.khxk.file, cid, 2)
                        this.upLoadImg(this.sfzz.file, cid, 3)
                        this.upLoadImg(this.sfzf.file, cid, 4)
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            this.yyzz = { file: null, src: "", snowid: "" }
            this.khxk = { file: null, src: "", snowid: "" }
            this.sfzz = { file: null, src: "", snowid: "" }
            this.sfzf = { file: null, src: "", snowid: "" }
            var snu='0'
            if(item.ISNEEDUPLOAD){
                snu = item.ISNEEDUPLOAD.toString()
            }
            var uni=""
            if(item.UNIQUEID){
                uni = item.UNIQUEID
            }
            this.postInfo = {
                SnowID: item.SnowID,
                displayname: item.DisplayName,
                regionsnowid: item.RegionSnowID,
                regionsnowids: item.RegionSnowID,
                cooperativetypesnowid: item.CooperativeTypeSnowID,
                address: item.Address,
                legalpersonname: item.LEGALPERSONNAME,
                legalpersonphone: item.LEGALPERSONPHONE,
                contactname: item.CONTACTNAME,
                contactphone: item.CONTACTPHONE,
                introduce: item.INTRODUCE,
                isblack: item.ISBLACK.toString(),
                loginName: item.LOGINNAME,
                PassWord: item.PassWord,
                ParentSnowID: item.ParentSnowID,
                businesslicensepath: "0",
                accountopeningpermitpath: "0",
                idcardfrontpath: "0",
                idcardbackpath: "0",
                IDNUMBER:item.IDNUMBER,
                UNIQUEID:uni,
                ISNEEDUPLOAD:snu,
            }
            //console.log(this.sysUser,item.ParentSnowID)
            if (this.sysType == "2") {
                this.getRegTreeByRegSnowid()
            } else {
                this.sysUserChange(item.ParentSnowID)
            }
            // this.getRegCheckList(item.RegionSnowID)
            this.getFileList(item.SnowID)
            this.doTypeStr = "修改服务组织"
            this.doType = "2"
            this.postModal = true
        },
        getRegCheckList(regId) {
            this.$http.post("Region/GetDataFather", { snowid: regId })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        var nl = []
                        // for (var i = 0; i < list.length; i++) {
                        //     nl.push(list[i].SNOWID)
                        // }
                        nl.push(regId)
                        this.postInfo.regionsnowids = regId
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        sendEdit() {
            this.canDo = false
            let params=this.postInfo
            params.ISNEEDUPLOAD = parseInt(this.postInfo.ISNEEDUPLOAD)
            params.isblack = parseInt(this.postInfo.isblack)
            this.showLoading("请稍后")
            //console.log(this.postInfo.ParentSnowID)
            this.$http.post("CooperativeUser/UpdateData", params)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        if (this.yyzz.file) {
                            this.upLoadImg(this.yyzz.file, this.postInfo.SnowID, 1)
                            if (this.yyzz.snowid != "") {
                                this.delImg(this.yyzz.snowid)
                            }
                        }
                        if (this.khxk.file) {
                            this.upLoadImg(this.khxk.file, this.postInfo.SnowID, 2)
                            if (this.khxk.snowid != "") {
                                this.delImg(this.khxk.snowid)
                            }
                        }
                        if (this.sfzz.file) {
                            this.upLoadImg(this.sfzz.file, this.postInfo.SnowID, 3)
                            if (this.sfzz.snowid != "") {
                                this.delImg(this.sfzz.snowid)
                            }
                        }
                        if (this.sfzf.file) {
                            this.upLoadImg(this.sfzf.file, this.postInfo.SnowID, 4)
                            if (this.sfzf.snowid != "") {
                                this.delImg(this.sfzf.snowid)
                            }
                        }
                        this.postModal = false
                        this.getPostList();
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除：' + item.DisplayName + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SnowID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("CooperativeUser/DeleteData", { SnowID: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        var d = this.validateImg()
                        if (d.v) {
                            if (this.doType == "1") {
                                this.sendAdd()
                            } else if (this.doType == "2") {
                                this.sendEdit()
                            }
                        } else {
                            this.$message.error(d.m)
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        validateImg() {
            var d = { v: true, m: "" }
            if (this.yyzz.file == null && this.yyzz.src == "") {
                d.v = false
                d.m = "请选择营业执照"
            }
            // else if (this.khxk.file == null && this.khxk.src == "") {
            //     d.v = false
            //     d.m = "请选择开户许可证"
            // } else if (this.sfzz.file == null && this.sfzz.src == "") {
            //     d.v = false
            //     d.m = "请选择身份证正面照"
            // } else if (this.sfzf.file == null && this.sfzf.src == "") {
            //     d.v = false
            //     d.m = "请选择身份证反面照"
            // }
            return d
        },
        beforeYYZZ(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            } else {
                var _URL = window.URL || window.webkitURL;
                this.yyzz.file = file
                this.yyzz.src = _URL.createObjectURL(file)
            }
            return isJPG;
        },
        beforeKHXK(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            } else {
                var _URL = window.URL || window.webkitURL;
                this.khxk.file = file
                this.khxk.src = _URL.createObjectURL(file)
            }
            return isJPG;
        },
        beforeSFZZ(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            } else {
                var _URL = window.URL || window.webkitURL;
                this.sfzz.file = file
                this.sfzz.src = _URL.createObjectURL(file)
            }
            return isJPG;
        },
        beforeSFZF(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            } else {
                var _URL = window.URL || window.webkitURL;
                this.sfzf.file = file
                this.sfzf.src = _URL.createObjectURL(file)
            }
            return isJPG;
        },
        upLoadImg(file, sourceid, type) {
            var fd = new FormData();
            fd.append("file", file);
            this.$upload.post("File/UpLoadFileNew?SourceSnowID=" + sourceid + "&FileType=" + type, fd)
                .then((response) => {
                    //this.hideLoading()
                    if (response.data.SUCCESS) {
                        //
                    } else {
                        //
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                });
        },
        delImg(id) {
            this.$http.post("File/DeleteFile", { SNOWID: id })
                .then((response) => {
                    //
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getFileList(sourceid) {
            this.$http.post("File/GetDataList", { SourceID: sourceid })
                .then((response) => {
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            var f = list[i]
                            if (f.TYPE == 1) {
                                this.yyzz = { file: null, src: f.PATH, snowid: f.SNOWID }
                            } else if (f.TYPE == 2) {
                                this.khxk = { file: null, src: f.PATH, snowid: f.SNOWID }
                            } else if (f.TYPE == 3) {
                                this.sfzz = { file: null, src: f.PATH, snowid: f.SNOWID }
                            } else if (f.TYPE == 4) {
                                this.sfzf = { file: null, src: f.PATH, snowid: f.SNOWID }
                            }
                        }
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        searchReport(item) {
            this.sysuserid = item.SYSUSERSNOWID
            this.searchModalTitle = item.SYSUSERDISPLAYNAME + "-作业报告查看"
            this.searchModal = true
            setTimeout(() => {
                this.$refs.search.resetDates()
            }, 100);
        },
        // 获取上级用户名称
        getUpperLevelUserName(id){
          let text = '/'
          this.sysUser.forEach((item)=>{
            if(item.SNOWID == id){
              text = item.DISPLAYNAME
            }
          })
          return text
        },

        exportMachineTrack(item){
            this.fileSysItem=item
            var  s=this.comjs.getDateMinStrByDate(new Date())
            var  e=this.comjs.getDateMinStrByDate(new Date(new Date().getTime()+86400000))
            this.exportMachineTrackTime=[new Date(s+" 00:00:00"),new Date(e+" 00:00:00")],
            this.exportMachineTrackStr=item.DisplayName+"农机轨迹导出"
            this.exportMachineTrackModal=true
            this.FPageIndex=1
            this.getFileCreateList()
        },
        getFileCreateList(){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                pageIndex:this.FPageIndex,
                pageSize:this.FPageSize,
                cooperativeSnowID:this.fileSysItem.SYSUSERSNOWID,
            }
            this.$http.post("ExportFile/GetDataList", params)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.FileList=response.data.DATA
                        this.FTotal=response.data.TOTALCOUNT
                    } else {
                        this.FileList=[]
                        this.FTotal=0
                    }
                })
                .catch((error) => {
                    this.FileList=[]
                    this.FTotal=0
                    this.hideLoading()
                    this.canDo=true   
                });
        },
        FPGChange(e) {
            this.FPageIndex = e
            this.getFileCreateList()
        },
        refFileTableList(){
            this.FPageIndex = 1
            this.getFileCreateList()
        },
        submitExportMachineTrackForm() {
            if (this.canDo) {
                var st=this.exportMachineTrackTime[0]
                var et =this.exportMachineTrackTime[1]
                if(et.getTime() -st.getTime() < 86400000){
                    this.$message.error("时间相距至少大于1天")
                }else{
                    this.sendExportMachineTrack(this.comjs.getDateStrByDate(st),this.comjs.getDateStrByDate(et))
                }
            }
        },
        downFile(item){
            if(item.PATH && item.PATH!=''){
                window.open(item.PATH)
            }else{
                this.$message.error("下载地址有误")
            }
        },
        cancelFile(item){
            if(this.canDo){
                this.$confirm('确定取消文件生成吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.sendCancelFile(item.SnowID)
                    }).catch(() => {
                    this.$message.info("已取消")    
                });
            }
        },
        sendCancelFile(id){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("ExportFile/CancleCreate", {snowID:id})
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已取消")
                        this.getFileCreateList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        },
        sendExportMachineTrack(st,et){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                SnowID:this.fileSysItem.SYSUSERSNOWID,
                StartTime:st,
                EndTime:et,
            }
            this.$http.post("PositionerHistory/ExportReportList", params)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$confirm('文件正在生成，您可以刷新列表查看生成情况', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'success'
                        })
                        this.getFileCreateList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        },

    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.imgItem {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
}
</style>