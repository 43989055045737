<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">农机管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
        <el-select v-model="njType" placeholder="请选择农机类型" filterable clearable class="iw">
          <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                     :value="item.SNOWID"></el-option>
        </el-select>
        <el-select v-model="njBarnd" placeholder="请选择农机品牌" filterable clearable class="iw">
          <el-option v-for="item in brandList" :key="item.SNOWID" :label="item.NAME"
                     :value="item.SNOWID"></el-option>
        </el-select>
        <el-button type="primary" @click="searchPost">查询</el-button>
        <el-button type="success" @click="addPost" class="fr">添加</el-button>

      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%">
          <el-table-column prop="NAME" label="名称"></el-table-column>
          <el-table-column prop="PHONE" label="手机号"></el-table-column>
          <el-table-column prop="SYSUSERNAME" label="服务组织用户"></el-table-column>
          <el-table-column prop="TOOLName" label="关联农具">
            <template slot-scope="scope">
              <span v-if="scope.row.TOOLName">{{ scope.row.TOOLName }}</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column prop="MECHINERYTYPE" label="类型"></el-table-column>
          <el-table-column prop="BRAND" label="品牌"></el-table-column>
          <el-table-column prop="MODEL" label="型号"></el-table-column>
          <el-table-column prop="POWER" label="功率"></el-table-column>
          <el-table-column prop="FACTORYNUMBER" label="出厂编号"></el-table-column>
          <el-table-column prop="ENGINENUMBER" label="发动机编号"></el-table-column>
          <el-table-column prop="BUYYEAR" label="购买日期">
            <template slot-scope="scope">
              <span>{{ scope.row.BUYYEAR ? scope.row.BUYYEAR.substring(0, 10) : '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="BZ" label="备注"></el-table-column>
          <el-table-column prop="PositionerName" label="定位器">
            <template slot-scope="scope">
              <span v-if="scope.row.PositionerName">{{ scope.row.PositionerName }}</span>
              <span v-else>未绑定</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template slot-scope="scope">
              <el-button @click="editPost(scope.row)" type="text" size="small"
                         style="color:#E6A23C">编辑
              </el-button>
              <el-button @click="delPost(scope.row)" type="text" size="small"
                         style="color:#F56C6C">删除
              </el-button>
              <el-button @click="unbindPost(scope.row)" type="text" size="small" style="color:#F56C6C"
                         v-if="scope.row.PositionerName && sysType == '1'">解绑定位器
              </el-button>
              <el-button @click="bindPost(scope.row)" type="text" size="small" style="color:#409EFF"
                         v-else-if="!scope.row.PositionerName && sysType == '1'">绑定定位器
              </el-button>
              <el-button  @click="exportTrail(scope.row)" type="text" size="small"
                         style="color:#409EFF;margin-left:0;">导出作业轨迹
              </el-button>
              <el-button v-if="sysType == '1'" @click="searchReport(scope.row)" type="text" size="small"
                         style="color:#409EFF">查看作业报告
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                       :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" top="30px" :close-on-click-modal="false">
      <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="150px" class="demo-uForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="postInfo.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="服务组织用户" prop="SYSUSERSNOWID">
          <el-select v-model="postInfo.SYSUSERSNOWID" style="width:100%;" clearable>
            <el-option v-for="item in teamList" :key="item.SYSUSERSNOWID" :label="item.DisplayName"
                       :value="item.SYSUSERSNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联农具">
          <el-select v-model="postInfo.toolSnowID" style="width:100%;">
            <el-option v-for="item in toolList" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="农机类型" prop="MECHINERYTYPESNOWID">
          <el-select v-model="postInfo.MECHINERYTYPESNOWID" style="width:100%;" @change="typeChange">
            <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="农机品牌" prop="BRANDSNOWID">
          <el-select v-model="postInfo.BRANDSNOWID" style="width:100%;" @change="brandChange">
            <el-option v-for="item in brandList" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="农机型号">
          <el-input v-model="postInfo.model" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农机功率">
          <el-input v-model="postInfo.power" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出厂编号">
          <el-input v-model="postInfo.factorynumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发动机编号">
          <el-input v-model="postInfo.enginenumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="购买日期">
          <el-date-picker v-model="postInfo.buyyears" type="date" placeholder="选择日期" style="width:100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="postInfo.bz" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="postModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="bindStr" :visible.sync="bindModal" width="900px" :close-on-click-modal="false">
      <el-form :model="bindInfo" status-icon :rules="bindrules" ref="bForm" label-width="150px" class="demo-uForm">
        <el-form-item label="定位器" prop="PositionerSnowID">
          <el-select v-model="bindInfo.PositionerSnowID" style="width:100%;" clearable>
            <el-option v-for="item in dwqList" :key="item.SNOWID" :label="item.NAME"
                       :value="item.NAME"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitBindForm()">提交</el-button>
          <el-button @click="bindModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="searchModalTitle" :visible.sync="searchModal" width="1600px" top="30px"
               :close-on-click-modal="false">
      <SearchReport :mechineid="mechineid" sysuserid="" :tableHeight="dialogMaxHeigh" ref="search"></SearchReport>
    </el-dialog>
    <el-dialog :title="trailTitle" :visible.sync="trailModal" width="900px" :close-on-click-modal="false">
      <el-form :model="trailInfo" status-icon :rules="trailrules" ref="tForm" label-width="150px" class="demo-uForm">
        <el-form-item label="时间" prop="trailTimes">
          <el-date-picker v-model="trailInfo.trailTimes" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false" style="width:100%;"> </el-date-picker>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="trailInfo.projectSnowID" style="width:100%;" clearable>
            <el-option v-for="item in projects" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitExportTrail()">提交</el-button>
          <el-button @click="trailModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
var h = document.body.clientHeight
import SearchReport from "../../components/SearchReport"

export default {
  name: "name",
  components: {
    SearchReport,
  },
  data() {
    var name = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var MECHINERYTYPESNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择农机类型"));
      } else {
        callback();
      }
    };
    var BRANDSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择农机品牌"));
      } else {
        callback();
      }
    };
    var model = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入农机型号"));
      } else {
        callback();
      }
    };
    var toolSnowID = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请选择农具"));
      } else {
        callback();
      }
    };
    var power = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入农机功率"));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('功率只能是数字'));
      } else {
        callback();
      }
    };
    var factorynumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入出厂编号"));
      } else {
        callback();
      }
    };
    var enginenumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入发动机编号"));
      } else {
        callback();
      }
    };
    var buyyears = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择购买日期"));
      } else {
        callback();
      }
    };
    var phone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)) {
        callback(new Error('手机号输入有误'));
      } else {
        callback();
      }
    };
    var SYSUSERSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择服务组织"));
      } else {
        callback();
      }
    };
    var PositionerSnowID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择定位器"));
      } else {
        callback();
      }
    };

    var trailTimes=(rule, value, callback) => {
      if (value ==='' ) {
        callback(new Error("请选择时间"));
      } else {
        callback();
      }
    };
    return {
      dialogMaxHeigh: 0,
      canDo: true,

      sysType: "1",
      regId: "",
      regUserId: "",

      name: "",
      njType: "",
      njBarnd: "",

      toolList: [],
      typeList: [],
      brandList: [],
      teamList: [],

      postList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,


      doTypeStr: "",
      doType: "",
      postModal: false,
      postInfo: {},
      postrules: {
        name: [{validator: name, trigger: "blur"}],
        MECHINERYTYPESNOWID: [{validator: MECHINERYTYPESNOWID, trigger: "blur"}],
        BRANDSNOWID: [{validator: BRANDSNOWID, trigger: "blur"}],
        model: [{validator: model, trigger: "blur"}],
        power: [{validator: power, trigger: "blur"}],
        factorynumber: [{validator: factorynumber, trigger: "blur"}],
        enginenumber: [{validator: enginenumber, trigger: "blur"}],
        buyyears: [{validator: buyyears, trigger: "blur"}],
        phone: [{validator: phone, trigger: "blur"}],
        SYSUSERSNOWID: [{validator: SYSUSERSNOWID, trigger: "blur"}],
        toolSnowID: [{validator: toolSnowID, trigger: "blur"}],
      },

      dwqList: [],
      bindStr: "",
      bindModal: false,
      bindInfo: {},
      bindrules: {
        PositionerSnowID: [{validator: PositionerSnowID, trigger: "blur"}],
      },

      mechineid: "",

      searchModalTitle: "",
      searchModal: false,

      projects:[],
      trailTitle:"",
      trailInfo:{},
      trailrules:{
        trailTimes:[{validator: trailTimes, trigger: "blur"}],
      },
      trailModal:false,
    };
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    if (this.sysType == "2") {
      this.regId = this.$store.state.xzqSnowid
      this.regUserId = this.$store.state.xzqUserSnowid
    }
    this.dialogMaxHeigh = h - 200
    this.loadItem = null
    this.getToolList()
    this.getTypeList()
    this.getBrandList()
    this.getTeamList()
    this.getPostList()
  },
  methods: {
    getTeamList() {
      this.$http.post("CooperativeUser/GetDataList", {ParentSnowID: this.regUserId})
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.teamList = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    getToolList() {
      this.$http.post("TooL/GetDataList", {CooperativeUserSnowID: this.postInfo.SYSUSERSNOWID})
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.toolList = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    typeChange(e) {
      if (e == "") {
        this.postInfo.mechinerytype = ""
      } else {
        for (var i = 0; i < this.typeList.length; i++) {
          if (this.typeList[i].SNOWID == e) {
            this.postInfo.mechinerytype = this.typeList[i].NAME
            break;
          }
        }
      }
    },
    getTypeList() {
      this.$http.post("MechineryType/GetDataList", {})
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.typeList = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    brandChange(e) {
      if (e == "") {
        this.postInfo.brand = ""
      } else {
        for (var i = 0; i < this.brandList.length; i++) {
          if (this.brandList[i].SNOWID == e) {
            this.postInfo.brand = this.brandList[i].NAME
            break;
          }
        }
      }
    },
    getBrandList() {
      this.$http.post("MechineryBrand/GetDataList", {})
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.brandList = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    getDwqList() {
      this.$http.post("Positioner/GetDataList", {IsBand: 0})
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.dwqList = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    getPostList() {
      this.showLoading("请稍后")
      var params = {
        PAGEINDEX: this.PageIndex,
        PAGESIZE: this.PageSize,
        NAME: this.name,
        BRANDSNOWID: this.njBarnd,
        MECHINERYTYPESNOWID: this.njType,
      }
      if(this.sysType == 2){
        params.regionSnowID = this.regId
      }
      this.$http.post("Mechinery/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.postList = response.data.DATA
              this.Total = response.data.TOTALCOUNT
            } else {
              this.postList = []
              this.Total = 0
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.postList = []
            this.Total = 0
          });
    },
    searchPost() {
      this.PageIndex = 1
      this.getPostList()
    },
    addPost() {
      this.postInfo = {
        owersnowid: "0",
        name: "",
        SYSUSERSNOWID: "",
        toolSnowID: "",
        MECHINERYTYPESNOWID: "",
        mechinerytype: "",
        BRANDSNOWID: "",
        brand: "",
        model: "",
        power: "",
        factorynumber: "",
        enginenumber: "",
        buyyear: "",
        buyyears: "",
        phone: "",
        bz: "",
      }
      this.doTypeStr = "添加农机"
      this.doType = "1"
      this.postModal = true
    },
    sendAdd() {
      this.canDo = false
      this.showLoading("请稍后")
      this.$http.post("Mechinery/InsertData", this.postInfo)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已添加")
              this.postModal = false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    editPost(item) {
      this.postInfo = {
        SnowID: item.SnowID,
        owersnowid: "0",
        name: item.NAME,
        SYSUSERSNOWID: item.SysUserSnowID,
        toolSnowID: item.TOOLSNOWID,
        MECHINERYTYPESNOWID: item.MECHINERYTYPESNOWID,
        mechinerytype: item.MECHINERYTYPE,
        BRANDSNOWID: item.BRANDSNOWID,
        brand: item.BRAND,
        model: item.MODEL,
        power: item.POWER,
        factorynumber: item.FACTORYNUMBER,
        enginenumber: item.ENGINENUMBER,
        buyyear: item.BUYYEAR,
        buyyears: item.BUYYEAR ? new Date(item.BUYYEAR) : '',
        phone: item.PHONE,
        bz: item.BZ,
      }
      this.doTypeStr = "修改农机"
      this.doType = "2"
      this.postModal = true
    },
    sendEdit() {
      this.canDo = false
      this.showLoading("请稍后")
      this.$http.post("Mechinery/UpdateData", this.postInfo)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已修改")
              this.postModal = false
              this.getPostList();
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    PGChange(e) {
      this.PageIndex = e
      this.getPostList()
    },
    submitPostForm() {
      if (this.canDo) {
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            if(this.postInfo.buyyears){
              this.postInfo.buyyear = this.comjs.getDateMinStrByDate(this.postInfo.buyyears)
            }
            this.postInfo.power = parseInt(this.postInfo.power)
            if (this.doType == "1") {
              this.sendAdd()
            } else if (this.doType == "2") {
              this.sendEdit()
            }
          } else {
            return false;
          }
        });
      }
    },
    delPost(item) {
      if (this.canDo) {
        this.$confirm('确定删除：' + item.NAME + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(item.SnowID)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }

    },
    sendDel(id) {
      this.canDo = false
      this.showLoading("请稍后")
      this.$http.post("Mechinery/DeleteData", {SnowID: id})
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.postModal = false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    unbindPost(item) {
      if (this.canDo) {
        this.$confirm('确定与定位器：' + item.PositionerName + '解绑？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendUnBind(item.SnowID)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }
    },
    sendUnBind(id) {
      this.canDo = false
      this.showLoading("请稍后")
      this.$http.post("Positioner/UnBindPositioner", {MechinerySnowID: id})
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已解绑")
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    bindPost(item) {
      this.getDwqList()
      this.bindStr = item.NAME + "-定位器绑定"
      this.bindInfo = {
        MechinerySnowID: item.SnowID,
        PositionerSnowID: "",
      }
      this.bindModal = true
    },
    submitBindForm() {
      if (this.canDo) {
        this.$refs.bForm.validate((valid) => {
          if (valid) {
            this.sendBind()
          } else {
            return false;
          }
        });
      }
    },
    sendBind() {
      this.canDo = false
      this.showLoading("请稍后")
      this.$http.post("Positioner/BindPositioner", this.bindInfo)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已绑定")
              this.bindModal = false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    searchReport(item) {
      this.mechineid = item.SnowID
      this.searchModalTitle = item.NAME + "-作业报告查看"
      this.searchModal = true
      setTimeout(() => {
        this.$refs.search.resetDates()
      }, 100);
    },

    getPrijectsList() {
      this.$http.post("Project/GetDataList", {})
          .then((response) => {
            if (response.data.SUCCESS) {
              this.projects = response.data.DATA
            }
          }).catch((error) => {
        this.errorFlag(JSON.stringify(error))
      });
    },
    exportTrail(item){
      this.getPrijectsList()
      this.trailTitle=item.NAME+'-导出作业轨迹'
      this.trailInfo={
        name:item.NAME,
        mechinerySnowID:item.SnowID,
        trailTimes:"",
        projectSnowID:"",
      }
      this.trailModal=true
    },
    submitExportTrail(){
      if (this.canDo) {
        this.$refs.tForm.validate((valid) => {
          if (valid) {
            this.sendExportTrail()
          } else {
            return false;
          }
        });
      }
    },
    sendExportTrail(){
      var st = this.comjs.getDateStrByDate(this.trailInfo.trailTimes[0])
      var et = this.comjs.getDateStrByDate(this.trailInfo.trailTimes[1])
      var params={
        mechinerySnowID:this.trailInfo.mechinerySnowID,
        projectSnowID:this.trailInfo.projectSnowID,
        startTime:st,
        endTime:et,
      }
      this.showLoading("请稍后")
      this.canDo = false
      this.$download.post("PositionerHistory/GetMachineryHistory", params,{ responseType: 'blob',timeout:300000})
          .then((resJson) => {
              this.hideLoading()
              this.canDo = true
              if (resJson) {
                  var name =this.trailInfo.name+"_"+st+"-"+et+"作业报告"
                  let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                  if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveBlob(blob, name + ".xls")
                  } else {
                      let downloadElement = document.createElement('a')
                      let href = window.URL.createObjectURL(blob) //创建下载的链接
                      downloadElement.href = href
                      downloadElement.download = name + ".xls"
                      document.body.appendChild(downloadElement)
                      //此写法兼容火狐
                      let evt = document.createEvent('MouseEvents')
                      evt.initEvent('click', false, false)
                      downloadElement.dispatchEvent(evt)
                      document.body.removeChild(downloadElement)
                  }
                  this.$message.success('操作成功!')
              } else {
                  this.$message.error("数据导出失败")
              }
          })
          .catch((error) => {
              this.errorFlag(JSON.stringify(error))
              this.hideLoading()
              this.canDo = true
          });
    },
  },
  computed: {},
  watch: {
    'postInfo.SYSUSERSNOWID':function () {
      this.getToolList()
    }
  },
};
</script>
<style></style>